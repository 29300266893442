.primary {
  @apply transition-all border border-green bg-green rounded-full py-2 px-5 font-medium hover:bg-white hover:border-white active:scale-95;
}

.secondary {
  @apply transition-all border border-green rounded-3xl py-2 px-5 mx-2 font-medium text-white text-opacity-80 hover:bg-white hover:text-black hover:opacity-100 hover:border-white active:scale-95;
}

.profile {
  @apply border border-green bg-green rounded-full p-3 hover:bg-white hover:border-white active:scale-95 mx-2;
}

.xl {
  @apply transition-all border border-green bg-green rounded-full py-4 px-16 font-semibold text-xl hover:bg-white hover:border-white active:scale-95;
}

.tarifs {
  @apply mt-10 transition-all w-full border border-green bg-green rounded-full py-5 px-5 font-semibold text-xl hover:bg-white active:scale-95;
}

.auth {
  @apply transition-all border border-green bg-green rounded-xl py-2 px-5 text-xl hover:bg-white hover:border-white active:scale-95 w-full;
}

.cancel {
  @apply bg-red rounded-full hover:text-white py-2 px-5 active:scale-95 m-2;
}

.pay {
  @apply transition-all border border-green bg-green rounded-full py-2 px-5 font-medium hover:bg-white hover:text-black active:scale-95 m-2;
}

.repeat {
  @apply transition-all border border-yelow bg-yelow rounded-full py-2 px-5 font-medium hover:bg-white hover:text-black active:scale-95 m-2;
}
