.form {
  @apply bg-fullbalck w-full px-4 sm:px-14  sm:w-[600px] p-12 shadow-md rounded-xl;
  &_group {
    & > div {
      @apply flex justify-between;

      label {
        @apply text-white text-lg mb-2;
      }

      input {
        @apply p-4 w-full rounded-xl mb-4 active:border-none outline-none;
      }

      p {
        @apply -mt-2 mb-2 text-red;
      }
    }

    & > div > div {
      @apply flex flex-col w-full mx-2;
    }
  }
}

.tel {
  input {
    @apply w-full font-sans;
  }
  & > div > div {
    @apply hidden;
  }
}
