header {
  @apply py-10 flex justify-between xl:px-0;

  .nav {
    @apply hidden xl:flex self-center;

    ul {
      @apply flex text-white;

      li {
        @apply mx-3 cursor-pointer opacity-60 hover:text-green hover:opacity-100;
      }
    }
  }
}
