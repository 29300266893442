.dropdown {
  font-family: sans-serif;
  @apply relative;
  &_nav {
    @apply absolute hidden opacity-0 top-12 right-0 p-4 bg-green rounded-lg transition-all;
    min-width: 240px;
    ul {
      @apply py-2;
      li {
        @apply flex items-center justify-between text-lg px-4 py-2 hover:bg-black cursor-pointer rounded-md active:scale-95 transition-all;
        ion-icon {
          --ionicon-stroke-width: 50px;
        }

        &:hover {
          @apply text-white;
        }
      }
      a {
        li {
          @apply flex items-center justify-between text-lg px-4 py-2 hover:bg-black cursor-pointer rounded-md active:scale-95 transition-all;
          ion-icon {
            --ionicon-stroke-width: 50px;
          }
        }
        &:hover {
          @apply text-white;
        }
        &:last-child {
          @apply hover:text-red;
        }
      }
    }
  }
  &:focus-within &_nav {
    @apply opacity-100 block transition-all;
  }
}
