.burger {
  @apply block xl:hidden float-left outline-none border-none p-3 bg-none;

  span {
    @apply transition-all block w-9 h-1 mt-2 bg-green;
  }

  &:hover {
    span:nth-of-type(1) {
      @apply w-6;
    }
    span:nth-of-type(2) {
      @apply w-8;
    }
    span:nth-of-type(3) {
      @apply w-4;
    }
  }

  &:active {
    span:nth-of-type(1) {
      @apply transform rotate-45 translate-x-3 translate-y-3 w-10;
    }

    span:nth-of-type(2) {
      @apply opacity-0 pointer-events-none;
    }

    span:nth-of-type(3) {
      @apply transform -rotate-45 translate-x-3 -translate-y-3 w-10;
    }
  }
}
