.table {
  @apply rounded-xl font-sans border-separate border-spacing-y-2 w-full;
  thead {
    tr {
      th {
        @apply p-3 border text-start;
      }
    }
  }
  tbody,
  thead {
    tr {
      td,
      th {
        @apply p-3 bg-white border border-lightgray;
        max-width: 150px;
      }

      td:first-child,
      th:first-child {
        @apply rounded-tl-xl rounded-bl-xl;
      }
      td:last-child,
      th:last-child {
        @apply rounded-tr-xl rounded-br-xl;
      }
    }

    td:last-child {
      @apply text-center;
    }
  }
}
