input,
textarea {
  @apply p-3 rounded-xl border border-lightgray;
}

.maincalc {
  @apply w-full bg-white rounded-2xl p-8 lg:mb-0 mb-10;
  p {
    @apply text-red;
  }

  .radio {
    @apply flex flex-wrap flex-col justify-center md:flex-row  md:justify-between mt-5;

    label {
      @apply cursor-pointer my-2;

      input {
        @apply hidden;
      }

      div {
        @apply flex items-center justify-center text-center text-sm font-medium bg-lightgray rounded-xl p-4 hover:bg-green hover:text-black transition-all active:scale-95 w-full md:w-40 h-10;
      }

      input:checked + div {
        @apply bg-green text-black;
      }

      input:disabled + div {
        @apply opacity-60;
      }
    }
  }
}

.sugs {
  @apply bg-white text-black border border-lightgray flex flex-col items-start p-5 rounded-lg absolute z-10 w-full shadow-sm mt-2;
}

.sug {
  @apply w-full p-2 hover:bg-lightgray rounded text-start;
}

.highlight {
  @apply bg-green p-1 rounded text-white;
}
