.order {
  @apply flex flex-col items-center;
  h1 {
    @apply text-white text-2xl lg:text-6xl my-6 lg:my-14 font-semibold;
  }

  h2 {
    @apply mb-10;
  }

  label {
    @apply text-white text-xl mb-4;
  }

  input,
  textarea {
    @apply p-4  rounded-xl mb-10 active:border-none outline-none;
  }
  p {
    @apply text-red opacity-80 -mt-8 mb-3;
  }

  .form {
    @apply flex flex-col justify-center w-full;

    @media (min-width: 768px) {
      width: 700px;
    }

    &_group {
      @apply flex flex-wrap md:flex-nowrap justify-around;
      & > div {
        @apply flex flex-col w-full mx-3;
      }
    }

    &_selector {
      @apply flex  flex-col mb-14;

      label {
        @apply mx-4 md:mx-0;
      }

      & > div {
        @apply flex flex-wrap flex-col justify-center md:flex-row  md:justify-between;

        // label {
        //   @apply cursor-pointer;

        //   input {
        //     @apply hidden;
        //   }

        //   div {
        //     @apply flex items-center justify-center text-center text-lg font-semibold border border-green rounded-xl p-4 hover:bg-green hover:text-black transition-all active:scale-95 w-full md:w-40 h-24;
        //   }

        //   input:checked + div {
        //     @apply bg-green text-black;
        //   }

        //   input:disabled + div {
        //     @apply opacity-60;
        //   }
        // }
      }
    }
  }
}

label {
  @apply cursor-pointer;

  input {
    @apply hidden;
  }

  div {
    @apply flex items-center justify-center text-center text-lg font-semibold border border-green rounded-xl p-4 hover:bg-green hover:text-black transition-all active:scale-95 w-full md:w-40 h-24;
  }

  input:checked + div {
    @apply bg-green text-black;
  }

  input:disabled + div {
    @apply opacity-60;
  }
}

.tel {
  input {
    @apply w-full;
  }
  & > div > div {
    @apply hidden;
  }
}

.datepicker {
  input#date {
    @apply text-black;
  }
}

// .adress > div {
//   @apply relative;
//   ul {
//     @apply bg-black text-white border border-green flex flex-col items-start p-5 rounded-lg -mt-9 absolute w-full;

//     li {
//       @apply hover:bg-gray;
//     }
//   }
// }

.adress {
  @apply relative;

  .sugs {
    @apply bg-white text-black border border-lightgray flex flex-col items-start p-5 rounded-lg absolute z-10 w-full shadow-sm -mb-9;
  }

  .sug {
    @apply w-full p-2 hover:bg-lightgray rounded text-start;
  }

  .highlight {
    @apply bg-green p-1 rounded text-white;
  }
}

.where_pay {
  & > div {
    @apply flex flex-col;

    label {
      @apply cursor-pointer;

      input {
        @apply hidden;
      }

      div {
        @apply flex items-start justify-start text-lg font-semibold border border-green rounded-md p-4 hover:bg-green hover:text-black transition-all active:scale-95 mx-4 md:mx-0 w-auto h-auto;
        min-height: 40px;
      }

      input:checked + div {
        @apply bg-green text-black;
      }

      input:disabled + div {
        @apply opacity-60;
      }
    }
  }
}
