.menu {
  @apply absolute top-0 right-0 bottom-0 z-50 flex flex-col w-0 max-w-full p-5 items-stretch bg-black -translate-x-[100%] transition-all;
}

.links {
  @apply flex flex-col justify-start items-start h-full text-2xl mt-5 w-full px-6 font-semibold;

  button {
    @apply flex items-center justify-between w-full mt-8 py-6;

    ion-icon {
      --ionicon-stroke-width: 32px;
    }
  }
}
