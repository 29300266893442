h2 {
  @apply text-white text-center leading-tight;
  font-size: 2.5rem;
}
section {
  @apply mt-20;

  @media (min-width: 1440px) {
    margin-top: 10rem;
  }
}

.main {
  @apply mt-3 xl:mt-20;
  @media (min-width: 1440px) {
    margin-top: 10rem;
  }

  &__container {
    @apply flex lg:flex-row flex-wrap-reverse justify-center xl:justify-between;
    &_left {
      @apply flex flex-col items-center text-center xl:text-start xl:items-start;
      max-width: 600px;

      h1 {
        @apply text-white leading-none;
        font-size: 3.5rem;
        @media (max-width: 500px) {
          font-size: 2rem;
        }
      }
      p {
        @apply text-white opacity-60 py-14;
        font-size: 1.25rem;
      }
    }
    &_right {
      // @apply flex flex-col justify-center mt-10 xl:mt-0;
      @apply flex flex-col justify-center items-center w-full;
      max-width: 600px;
    }
  }
}

.advantages {
  h2 {
    @apply text-center;
  }
  &_block {
    margin-top: 3.5rem;
    @apply flex flex-wrap justify-center xl:justify-between w-full bg-white text-black text-center rounded-3xl py-10 px-24;
    & > div {
      max-width: 14rem;
      & > h3 {
        @apply text-4xl font-semibold;
      }
      & > p {
        @apply text-xl opacity-60 mt-6;
      }
    }
  }
}

.region {
  @apply flex flex-wrap justify-between items-center;
  h2 {
    @apply text-start;
  }
  &_left {
    max-width: 600px;
  }
  &_right {
    max-width: 600px;

    ul {
      @apply mt-14;
      li {
        @apply relative left-10 text-white text-xl text-opacity-60 before:content-[''] before:absolute before:w-4 before:h-4 before:bg-green before:rounded-full before:-left-10 before:top-4;
        line-height: 3rem;
      }
    }
  }
}

.delivery {
  &_container {
    @apply flex flex-wrap justify-around mt-14;
    & > div {
      @apply relative my-3 py-14 px-8  bg-white rounded-3xl;
      height: 232px;
      width: 416px;
      h3 {
        @apply font-semibold text-3xl;
      }
      p {
        @apply opacity-60 text-xl mt-6;
      }
      img {
        @apply absolute right-0 top-3;
        height: 90%;
      }
    }
  }
}

.how {
  &_container {
    @apply flex flex-col flex-wrap  xl:flex-row justify-center xl:justify-between mt-14;

    & > div {
      @apply text-center;
      span {
        @apply bg-green rounded-full py-4 px-8;
        font-size: 2rem;
      }

      p {
        @apply text-white opacity-60 mt-6 text-xl mb-8;
      }
    }
  }
}

.tarifs {
  &_container {
    @apply flex flex-wrap justify-around xl:justify-between mt-14;
    div {
      @apply bg-white p-14 mb-5;
      width: 416px;
      border-radius: 2.5rem;

      h3 {
        @apply font-semibold mb-4;
        font-size: 2rem;
      }

      span {
        @apply text-green;
        font-size: 3.5rem;
      }
      ul {
        @apply mt-8;
        li {
          @apply relative left-6 text-xl  text-opacity-60 before:content-['']  before:absolute before:w-2 before:h-2 before:bg-black before:rounded-full before:-left-6 before:top-4;
          line-height: 2.5rem;
        }
      }
    }
  }
}

.payments {
  &_container {
    @apply flex flex-wrap justify-between items-center;

    &_left {
      max-width: 600px;

      p {
        @apply text-white text-xl mt-14 leading-8 opacity-60;
      }

      ul {
        @apply mt-14;

        li {
          @apply relative left-10 text-white text-xl before:content-[''] before:absolute before:w-4 before:h-4 before:bg-green before:rounded-full before:-left-10 before:top-4;
          line-height: 3rem;
        }
      }
    }

    &_right {
      @apply mt-10 xl:mt-0;
      max-width: 600px;
    }
  }
}
