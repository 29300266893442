.sort {
  @apply flex;

  & label:not(:first-child) {
    @apply ml-2;
  }

  label {
    @apply cursor-pointer;

    input {
      @apply hidden;
    }

    div {
      @apply flex items-center justify-center text-[12px] xl:text-sm text-center border text-white border-green rounded-xl hover:bg-green hover:text-black transition-all active:scale-95 px-4 py-2 w-auto h-auto;
    }

    input:checked + div {
      @apply bg-green text-black;
    }
  }
}
